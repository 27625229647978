export default {
  badge: 'знак',
  open: 'Open',
  close: 'Закрыть',
  confirmEdit: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  dataIterator: {
    noResultsText: 'Не найдено подходящих записей',
    loadingText: 'Запись загружается...',
  },
  dataTable: {
    itemsPerPageText: 'Строк на странице:',
    ariaLabel: {
      sortDescending: 'Упорядочено по убыванию.',
      sortAscending: 'Упорядочено по возрастанию.',
      sortNone: 'Не упорядочено.',
      activateNone: 'Активируйте, чтобы убрать сортировку.',
      activateDescending: 'Активируйте для упорядочивания убыванию.',
      activateAscending: 'Активируйте для упорядочивания по возрастанию.',
    },
    sortBy: 'Сортировать по',
  },
  dataFooter: {
    itemsPerPageText: 'Записей на странице:',
    itemsPerPageAll: 'Все',
    nextPage: 'Следующая страница',
    prevPage: 'Предыдущая страница',
    firstPage: 'Первая страница',
    lastPage: 'Последняя страница',
    pageText: '{0}-{1} из {2}',
  },
  dateRangeInput: {
    divider: 'to',
  },
  datePicker: {
    itemsSelected: '{0} selected',
    range: {
      title: 'Select dates',
      header: 'Enter dates',
    },
    title: 'Select date',
    header: 'Enter date',
    input: {
      placeholder: 'Enter date',
    },
  },
  noDataText: 'Отсутствуют данные',
  carousel: {
    prev: 'Предыдущий слайд',
    next: 'Следующий слайд',
    ariaLabel: {
      delimiter: 'Слайд {0} из {1}',
    },
  },
  calendar: {
    moreEvents: 'Еще {0}',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
    otp: 'Please enter OTP character {0}',
  },
  fileInput: {
    counter: 'Файлов: {0}',
    counterSize: 'Файлов: {0} (всего {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Навигация по страницам',
      next: 'Следующая страница',
      previous: 'Предыдущая страница',
      page: 'Перейти на страницу {0}',
      currentPage: 'Текущая страница, Страница {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  stepper: {
    next: 'Next',
    prev: 'Previous',
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
